globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"x0bf-iV4fDNN4koq7MACn"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn:
    process.env.NODE_ENV === "development"
      ? ""
      : "https://fe441e60038146b3aa220505c1a4c694@o4507724408291328.ingest.de.sentry.io/4507724409798736",
  // Replay may only be enabled for the client-side
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      //  maskAllText: true,
      //  blockAllMedia: true,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
